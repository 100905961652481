import _defineProperty from "/builds/u5yGSXwH/11/ms/ecom/deep/presentation-react/apps/next-commerce/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { useContext, useState } from 'react';
import { sessionStorage } from 'react-storage';
import { useUserActivity } from '@src/utils/useUserActivity';
import { useCurrentUser } from '@src/utils/useCurrentUser';
import { InviteCoWorkerWalkMeContext, InviteCoWorkerWalkMeConstants, getInviteCoWorkerStorageOverride } from '@src/routes/InviteCoWorker/InviteCoWorkerWalkMe';
export var useInviteCoWorkerWalkMe = function useInviteCoWorkerWalkMe() {
  var _useCurrentUser = useCurrentUser(),
      userIsLoggedIn = _useCurrentUser.userIsLoggedIn;

  var _useContext = useContext(InviteCoWorkerWalkMeContext),
      state = _useContext.state,
      setEnabled = _useContext.setEnabled,
      dismissStep = _useContext.dismissStep,
      startTour = _useContext.startTour,
      advanceActiveStepNumber = _useContext.advanceActiveStepNumber;

  var _useUserActivity = useUserActivity(InviteCoWorkerWalkMeConstants.TRACKING_DATA_KEY, getInviteCoWorkerStorageOverride(userIsLoggedIn)),
      userActivityData = _useUserActivity.userActivityData,
      trackingData = _useUserActivity.trackingData,
      initialTrackingData = _useUserActivity.initialTrackingData,
      updateUserActivity = _useUserActivity.updateUserActivity;

  var _useState = useState(!!sessionStorage.getItem(InviteCoWorkerWalkMeConstants.DISMISSED_KEY)),
      dismissedAlert = _useState[0],
      setDismissedAlert = _useState[1];

  var dismissedCount = (trackingData === null || trackingData === void 0 ? void 0 : trackingData.numWalkMeDismissals) || 0;
  var shouldHideAlert = dismissedAlert || !userActivityData || (initialTrackingData === null || initialTrackingData === void 0 ? void 0 : initialTrackingData.hasCompletedWalkMe) || dismissedCount >= InviteCoWorkerWalkMeConstants.MAX_DISMISSALS;

  var dismissAlert = function dismissAlert(optOutOfWalkMe) {
    if (optOutOfWalkMe) setEnabled(false);
    sessionStorage.setItem(InviteCoWorkerWalkMeConstants.DISMISSED_KEY, true);
    setDismissedAlert(true);
    updateUserActivity(_objectSpread({
      numWalkMeDismissals: (dismissedCount || 0) + 1
    }, optOutOfWalkMe != null && {
      hasOptedOutOfWalkMe: optOutOfWalkMe
    }));
  };

  return {
    state: state,
    setEnabled: setEnabled,
    dismissStep: dismissStep,
    startTour: startTour,
    dismissAlert: dismissAlert,
    shouldHideAlert: shouldHideAlert,
    advanceActiveStepNumber: advanceActiveStepNumber
  };
};